
import React from 'react'

export default ({id, path, title, target, workplace}) => {

  return(
    <a className="sb1-joblist__link" key={id} href={path} target={target}>
      {workplace}: {title}

        <span className="sb1-joblist__link-icon">
        </span>
    </a>
  )
}
